import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import FilterNav from "components/FilterNav/FilterNav";
import ModalBox from "components/Modal/ModalBox";
import CustomFilter from "components/Reports/CustomFilter";
import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";
import { PAGES } from "helpers/Constant";
import {
  convertGridColumnMenu,
  getCurrentPageAllFiltersFromFilterStore,
  uuidv4,
} from "helpers/ReusableFunctions";
import ExportModal from "pages/PipelineReport/components/ExportModal";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDomainHealthData } from "services/FetchApiData";

const sortableColumn = true;
const pinnableColumn = true;

const DomainHealth = () => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const isDataFetch = useRef(false);

  const {
    isInternalUser,
    filterStore,
    applyFilterFlags: { applyFilterInDomainHealth },
  } = useContext(FilterContext);

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const columns = [
    {
      field: "address",
      headerName: "Mailbox / Domain",
      width: 300,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "total_replies",
      headerName: "Total Replies",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "unique_replies",
      headerName: "Unique Replies",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "prospect_count",
      headerName: "Total Prospects",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "campaign_count",
      headerName: "Total Campaigns",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "reply_ratio",
      headerName: "Reply Ratio",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      sortComparator: (v1, v2) => Number(v1) - Number(v2),
    },
    {
      field: "domain_type",
      headerName: "Providers",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
  ];

  const [initialRenderFlag, setInitialRenderFlag] = useState(true);
  const [domainHealthData, setDomainHealthData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columnsOrder, setColumnsOrder] = useState(columns);
  const [showExportModal, setShowExportModal] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState();

  const { language } = useContext(UserContext);

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const fetchDomainHealthData = async () => {
    if (isDataFetch.current) return;
    try {
      isDataFetch.current = true;
      setIsLoading(true);
      const response = await getDomainHealthData(
        getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.DOMAIN_HEALTH
        )
      );
      setDomainHealthData(
        response?.data?.data?.reports?.domain_health?.data?.map((el) => ({
          ...el,
          id: uuidv4(),
        })) || []
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      isDataFetch.current = false;
    }
  };

  const handleShowExportModal = () => {
    setShowExportModal((current) => !current);
  };

  useEffect(() => {
    setInitialRenderFlag(false);
  }, []);

  useEffect(() => {
    if (!initialRenderFlag) {
      fetchDomainHealthData();
    }
    return () => setDomainHealthData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialRenderFlag,
    applyFilterInDomainHealth,
    filterStore.Domains,
    filterStore.startDate,
    filterStore.endDate,
  ]);

  return (
    <div>
      <h4>Domain Health Report</h4>
      <FilterNav />
      <div className="card mt-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="flex-shrink-1">List of All Mailbox / Domain</h5>
            <div className="d-flex gap-0 flex-shrink-0">
              <div
                className="action-button align-self-center"
                onClick={handleShowExportModal}
              >
                <div className="m-0">{t("performanceReportText.export")}</div>
              </div>
            </div>
          </div>
          <div
            className="cstm-mui-datagrid"
            style={{ height: 500, width: "100%" }}
          >
            <DataGridPro
              loading={isLoading}
              rows={domainHealthData}
              columns={columnsWithOperators}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
              }}
              getRowId={(row) => row?.id}
              onColumnOrderChange={(c) => {
                setColumnsOrder((prevCols) => {
                  const newCols = [...prevCols];
                  newCols.splice(c.oldIndex, 1);
                  newCols.splice(c.targetIndex, 0, c.column);
                  return newCols;
                });
              }}
              initialState={{
                ...columns.initialState,
                columns: columns.initialState?.columns,
              }}
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              hideFooter
              onColumnVisibilityModelChange={(hideColumns) => {
                const hideColsName = Object.entries(hideColumns)
                  .filter(([_key, value]) => value === false)
                  ?.map((col) => col[0]);
                setHiddenColumns(hideColsName);
              }}
              rowsLoadingMode={"server"}
            />
          </div>
        </div>
      </div>
      <ModalBox
        show={showExportModal}
        onHide={handleShowExportModal}
        title="Export File"
        content={
          <ExportModal
            fileName="Mailbox / Domain"
            setShowModal={setShowExportModal}
            columns={columnsOrder}
            apiRef={apiRef}
            hiddenColumns={hiddenColumns}
          />
        }
      />
    </div>
  );
};

export default DomainHealth;
