import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { isEmpty, isEqual } from "lodash";
import { useLocation } from "react-router-dom";

import "./PerformanceReport.scss";

import {
  compareRangeInterval,
  getDetailedBarData,
  getPitchPerformance,
} from "../../services/FetchApiData";
import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";

import FilterNav from "components/FilterNav/FilterNav";
import SelectUI from "components/Select/SelectUI";
import DonutChart from "components/Charts/DonutChart";
import LineRecharts from "components/Charts/LineRecharts";
import PageTitle from "components/PageTitle/PageTitle";
import BarRecharts from "components/Charts/BarRecharts";
import CircleLabel from "components/UI/Circle/CircleLabel";
import ButtonUI from "components/UI/Button/ButtonUI";
import Circlebar from "components/UI/Circle/Circlebar";
import CustomError from "components/UI/Error/CustomError";

import {
  convertData,
  convertISODateRangeToLocalDateRange,
  getBenchmarkData,
  getCurrentPageAllFiltersFromFilterStore,
  selectOptionConverter,
} from "helpers/ReusableFunctions";
import {
  APPLY_FILTER_PAGE,
  ChartsColor,
  ComparisonStartDates,
  FetchLineDataCount,
  FetchLineDataUnit,
  PAGES,
  applicantReportType,
  barChartNameConvention,
  lastThreeMonthsEnd,
  lastThreeMonthsStart,
  pieChartNameConvention,
  reportBarChartDatakeys,
  reportLineChartDataLabels,
  reportLineChartDatakeys,
  reportTypeOptions,
} from "helpers/Constant";
import ErrorIcon from "assets/svg/error-fill.svg";
import ApplicantDetailsTable from "./ApplicantDetailsTable";
import { useTranslation } from "react-i18next";

const PerformanceReport = () => {
  const { t } = useTranslation();
  const {
    filterStore,
    setFilterStore,
    setLoading,
    dropdownData,
    isPresentationEnabled,
    Clients,
    applyFilterFlags: { applyFilterInEmployeeBranding },
    changeApplyFilterFlag,
    filterStoreToPassWhenTopFiltersChange,
    setLastAppliedFilterstore,
    overrideFilterStoreWithSavedFilters,
    leftHandFilterParams,
    isInternalUser,
    lastAppliedFilterstore,
    yourIndustryId,
  } = useContext(FilterContext);
  const {
    selectedGraph,
    setSelectedGraph,
    language,
    loggedInUserData,
    User,
    userGroup,
  } = useContext(UserContext);

  const [marketData, setMarketData] = useState();
  const [industryData, setIndustryData] = useState();
  const [companyData, setCompanyData] = useState();
  const [detailedBarData, setDetailedBarData] = useState();
  const [getAllFlags, setGetAllFlags] = useState({
    getAllFlagA: false,
    getAllFlagB: false,
    getAllFlagC: false,
  });
  const [initialRenderFlag, setInitialRenderFlag] = useState(true);

  const location = useLocation();
  const state = location.state;

  const [locationStateUsed, setLocationStateUsed] = useState(
    !location.state?.customReportDetail
  );

  const initialLoaderValue = {
    table: false,
    piechart: false,
    barchart: false,
    linechart: false,
    industrypiechart: false,
  };
  const [isLoader, setIsLoader] = useState(initialLoaderValue);
  const [lineChartData, setLineChartData] = useState([]);
  const [showError, setShowError] = useState(false);

  const setSelectedPitchResponseValue = (selectedValue) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      PitchResponse: selectedValue.value,
    }));
  };

  const handlerLoader = (obj) => {
    setIsLoader((prevLoader) => ({
      ...prevLoader,
      ...obj,
    }));
  };

  const handleBeforeUnload = () => {
    setSelectedGraph("Pie Chart");
  };

  const handleSetLocationUsed = () => {
    setLocationStateUsed(true);
  };

  const fetchIndustryPieData = async (modifiedFilterStore) => {
    try {
      setLoading(true);
      handlerLoader({ industrypiechart: true });

      const benchMarkData = await getPitchPerformance(
        getCurrentPageAllFiltersFromFilterStore(
          modifiedFilterStore,
          isInternalUser,
          PAGES.EMPLOYEE_BRANDING
        ),
        Clients
      );
      const convertedData = benchMarkData.data.data?.reports.pitch_performance;
      setIndustryData(convertData(getBenchmarkData(convertedData, "industry")));
      setLastAppliedFilterstore(modifiedFilterStore);
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setLoading(false);
      handlerLoader({ industrypiechart: false });
    }
  };

  const selectedIndustryValue = (selectedIndustry) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      Industry: selectedIndustry,
    }));
  };

  const fetchPieData = async (modifiedFilterStore) => {
    try {
      setLoading(true);
      handlerLoader({ piechart: true });
      const benchMarkData = await getPitchPerformance(
        getCurrentPageAllFiltersFromFilterStore(
          modifiedFilterStore,
          isInternalUser,
          PAGES.EMPLOYEE_BRANDING
        ),
        Clients
      );
      const convertedData = benchMarkData.data.data?.reports.pitch_performance;
      setCompanyData(convertData(getBenchmarkData(convertedData, "company")));
      setMarketData(convertData(getBenchmarkData(convertedData, "market")));
      setLastAppliedFilterstore(modifiedFilterStore);
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setLoading(false);
      handlerLoader({ piechart: false });
    }
  };

  const fetchLineChartData = async (modifiedFilterStore) => {
    const findReportValue = (intervals, i = 0) => {
      return intervals.map(
        (item) =>
          item.data[
            applicantReportType[modifiedFilterStore.PitchResponse || "yes"]
          ]
      )[i];
    };

    const generateDataAndRange = (
      response,
      dataList,
      ranges,
      count,
      isSecondary = false
      // rangesSecondary = null
    ) => {
      const convertedData =
        response.data.data?.reports.pitch_performance_interval;

      const properCount = Math.min(
        convertedData[0].intervals.length - 1,
        count
      );

      for (let i = 0; i <= properCount; i++) {
        if (
          new Date(convertedData[0].intervals[i]?.range.interval_start) <
          ComparisonStartDates[modifiedFilterStore.PitchResponse || "yes"]
        ) {
          setShowError(true);
        }

        const tempRange = convertISODateRangeToLocalDateRange(
          convertedData[0].intervals[i]?.range
        );

        const raw = {};
        convertedData.forEach((item) => {
          raw[item.benchmark] = findReportValue(item.intervals, i);
          raw.range = JSON.stringify(tempRange);
          raw.isSecondary = isSecondary;

          // if (rangesSecondary && rangesSecondary.length > 0) {
          //   const index = rangesSecondary.findIndex(
          //     (item) =>
          //       item.interval_end ===
          //       convertedData[0].intervals[i].range.interval_end
          //   );
          //   raw.secondaryRange =
          //     index >= 0 ? JSON.stringify(rangesSecondary[index]) : null;
          // }
        });

        dataList.push(raw);
        ranges.push(convertedData[0].intervals[i]?.range);
      }
    };

    try {
      setLoading(true);
      handlerLoader({ linechart: true });
      setLineChartData([]);

      let dataList = [];
      let ranges = [];
      let dataListSecondary = [];
      let rangesSecondary = [];
      const response = await compareRangeInterval(
        getCurrentPageAllFiltersFromFilterStore(
          modifiedFilterStore,
          isInternalUser,
          PAGES.EMPLOYEE_BRANDING
        ),
        Clients
      );

      generateDataAndRange(
        response,
        dataList,
        ranges,
        modifiedFilterStore.count
      );

      if (
        modifiedFilterStore.count <= 2 &&
        modifiedFilterStore.unit !== "custom"
      ) {
        const dynamicCount =
          FetchLineDataCount[modifiedFilterStore.unit] *
          (modifiedFilterStore.count + 1);

        // const weekEndDate =
        //   today < filterStore.endDate ? today : filterStore.endDate;

        const payload =
          modifiedFilterStore.unit === "month"
            ? {
                count: dynamicCount,
                unit: FetchLineDataUnit[modifiedFilterStore.unit],
                // startDate: FetchLineDataDates[modifiedFilterStore.unit].startDate,
                // endDate: FetchLineDataDates[modifiedFilterStore.unit].endDate,
                startDate: new Date(
                  modifiedFilterStore.endDate.getFullYear(),
                  modifiedFilterStore.endDate.getMonth(),
                  modifiedFilterStore.endDate.getDate() - 6
                ),
                endDate: modifiedFilterStore.endDate,
              }
            : modifiedFilterStore.unit === "quarter"
            ? {
                count: dynamicCount - 1,
                unit: FetchLineDataUnit[modifiedFilterStore.unit],
                startDate: new Date(
                  modifiedFilterStore.endDate.getFullYear(),
                  modifiedFilterStore.endDate.getMonth(),
                  1
                ),
                endDate: modifiedFilterStore.endDate,
              }
            : {
                count: dynamicCount - 1,
                unit: FetchLineDataUnit[modifiedFilterStore.unit],
                // startDate: new Date(
                //   modifiedFilterStore.startDate.getFullYear(),
                //   modifiedFilterStore.endDate.getMonth() - 2,
                //   1
                // ),
                // endDate: modifiedFilterStore.endDate,
              };

        const monthCount =
          modifiedFilterStore.unit === "custom"
            ? modifiedFilterStore.count
            : modifiedFilterStore.unit === "month"
            ? dynamicCount
            : dynamicCount - 1;

        try {
          const responseSecondary = await compareRangeInterval(
            getCurrentPageAllFiltersFromFilterStore(
              {
                ...modifiedFilterStore,
                ...payload,
              },
              isInternalUser,
              PAGES.EMPLOYEE_BRANDING
            ),
            Clients
          );
          setLastAppliedFilterstore(modifiedFilterStore);
          generateDataAndRange(
            responseSecondary,
            dataListSecondary,
            rangesSecondary,
            monthCount,
            true
            // rangesSecondary
          );
          dataList.forEach((item) => dataListSecondary.push(item));
          dataListSecondary.sort(
            (a, b) =>
              new Date(JSON.parse(a.range).interval_end) -
              new Date(JSON.parse(b.range).interval_end)
          );
          setLineChartData(dataListSecondary);
          // if (filterStore.unit === "quarter") {
          //   dataList.forEach((item) => {
          //     const i = dataListSecondary.findIndex(
          //       (entry) =>
          //         JSON.parse(entry.range).interval_end ===
          //         JSON.parse(item.range).interval_end
          //     );
          //     dataListSecondary.splice(i + 1, 0, item);
          //   });
          // } else if (
          //   filterStore.unit === "year" ||
          //   filterStore.unit === "month"
          // ) {
          // }
        } catch (error) {
          console.log(error);
        }
      } else {
        setLineChartData(dataList);
      }
      setLastAppliedFilterstore(modifiedFilterStore);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      handlerLoader({ linechart: false });
    }
  };

  const fetchBarData = async (modifiedFilterStore) => {
    try {
      setLoading(true);
      handlerLoader({ barchart: true });

      const barGraphDetailedData = await getDetailedBarData(
        getCurrentPageAllFiltersFromFilterStore(
          modifiedFilterStore,
          isInternalUser,
          PAGES.EMPLOYEE_BRANDING
        ),
        Clients
      );
      const rawBarData =
        barGraphDetailedData.data.data.reports.pitch_declined_reasons;

      const chartData = rawBarData.reduce((result, { benchmark, data }) => {
        for (const key in data) {
          const existObj = result.find((el) => el.name === key);

          if (existObj) {
            existObj[benchmark] = data[key];
          } else {
            result.push({
              name: key,
              [benchmark]: data[key],
            });
          }
        }
        return result;
      }, []);

      setDetailedBarData(chartData);
      setLastAppliedFilterstore(modifiedFilterStore);
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setLoading(false);
      handlerLoader({ barchart: false });
    }
  };

  const convertBarChartNames = (datalist = []) => {
    return datalist.map((data) => {
      if (data.name) {
        return {
          ...data,
          name: barChartNameConvention.find((item) => item.name === data.name)
            .value,
        };
      }
      return data;
    });
  };

  const getLineChartName = () => {
    const index = pieChartNameConvention.findIndex(
      (item) => item.value === filterStore?.PitchResponse
    );

    return (
      <>
        {" - "}
        {filterStore.PitchResponse ? (
          <span style={{ color: ChartsColor[index] }}>
            {filterStore?.PitchResponse?.charAt(0)?.toUpperCase() +
              filterStore?.PitchResponse?.substring(1)}
          </span>
        ) : (
          <span style={{ color: ChartsColor[0] }}>Yes</span>
        )}
        <span>{t("performanceReportText.applicants")}</span>
      </>
    );
  };

  const getAllDataHandler = () => {
    setGetAllFlags({
      getAllFlagA: true,
      getAllFlagB: true,
      getAllFlagC: true,
    });
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      PitchResponse: "yes",
      Company: [],
      ...Object.keys(leftHandFilterParams).reduce((finalObj, key) => {
        return {
          ...finalObj,
          [key]: [],
        };
      }, {}),
      startDate: lastThreeMonthsStart,
      endDate: lastThreeMonthsEnd,
      count: 0,
      unit: "quarter",
      Industry: yourIndustryId
        ? selectOptionConverter([yourIndustryId])[0]
        : [],
    }));
    changeApplyFilterFlag(APPLY_FILTER_PAGE.employee_branding);
    setSelectedGraph("Pie Chart");
  };

  const updatedLineChartDataLabels = reportLineChartDataLabels.map(
    (labelObj) => {
      if (labelObj.name === "Your Company") {
        if (
          userGroup.includes("internal") &&
          isPresentationEnabled &&
          Clients.value
        ) {
          return {
            name: Clients?.label,
          };
        }
        if (User?.signInUserSession?.idToken?.payload["custom:alt_org_name"]) {
          return language === "en"
            ? {
                // name: organization?.organization?.industry
                name: User?.signInUserSession?.idToken?.payload[
                  "custom:alt_org_name"
                ],
              }
            : { name: t("barChartText.company") };
        }
        return { name: loggedInUserData?.organization?.name ?? "Company" };
      }
      return language === "en"
        ? labelObj
        : { name: t(`barChartText.${labelObj.name.toLowerCase()}`) };
    }
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const finalFilterStore = !locationStateUsed
      ? {
          ...overrideFilterStoreWithSavedFilters(
            filterStoreToPassWhenTopFiltersChange
          ),
        }
      : getAllFlags.getAllFlagA || initialRenderFlag
      ? {
          ...filterStore,
        }
      : {
          ...filterStoreToPassWhenTopFiltersChange,
        };
    if (getAllFlags.getAllFlagA) {
      setGetAllFlags((prev) => ({
        ...prev,
        getAllFlagA: false,
      }));
    }
    if (finalFilterStore.count > 0) {
      setSelectedGraph("Line Chart");
      fetchLineChartData(finalFilterStore);
    } else if (finalFilterStore.PitchResponse === "no") {
      setSelectedGraph("Bar Chart");
      fetchBarData(finalFilterStore);
    } else {
      setSelectedGraph("Pie Chart");
      if (initialRenderFlag) {
        fetchIndustryPieData(finalFilterStore);
        fetchPieData(finalFilterStore);
      } else {
        if (
          !isEqual(lastAppliedFilterstore.Industry, finalFilterStore.Industry)
        ) {
          fetchIndustryPieData(finalFilterStore);
        } else {
          fetchPieData(finalFilterStore);
          fetchIndustryPieData(finalFilterStore);
        }
      }
    }
    setShowError(false);
    // eslint-disable-next-line
  }, [
    filterStore.startDate,
    filterStore.endDate,
    filterStore.unit,
    filterStore.count,
    filterStore.PitchResponse,
    filterStore.Industry,
  ]);

  useEffect(() => {
    const finalFilterStore = !locationStateUsed
      ? { ...overrideFilterStoreWithSavedFilters(filterStore) }
      : {
          ...filterStore,
        };
    if (!initialRenderFlag) {
      if (finalFilterStore.PitchResponse === "no") {
        fetchBarData(finalFilterStore);
      } else if (finalFilterStore.count > 0) {
        fetchLineChartData(finalFilterStore);
      } else {
        fetchPieData(finalFilterStore);
        fetchIndustryPieData(finalFilterStore);
      }
      setShowError(false);
    }
    // eslint-disable-next-line
  }, [applyFilterInEmployeeBranding]);

  const pageTitle = useMemo(() => {
    if (!isEmpty(state?.customReportDetail)) {
      return state.customReportDetail.title;
    }
    if (location.pathname === "/performancereport") {
      return t("performanceReportText.headTitle");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, language]);

  useEffect(() => {
    setInitialRenderFlag(false);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageTitle
          title={pageTitle}
          back={t("performanceReportText.backToList")}
        />
        <div>
          <ButtonUI
            outline="no-outline"
            title={t("performanceReportText.getAll")}
            loading={isLoader.table}
            onClick={() => getAllDataHandler()}
          />
        </div>
      </div>
      <FilterNav />
      <div className="d-flex flex-column flex-md-row justify-content-start gap-md-3 mt-3 align-items-md-baseline">
        <h5 className="mt-4 mb-0 mt-md-0 order-last order-md-0">
          {t("performanceReportText.employerBrandingReportComparisons")}
          {selectedGraph === "Line Chart" ? getLineChartName() : ""}
          {selectedGraph === "Bar Chart"
            ? t("performanceReportText.reasonsForNo")
            : ""}
        </h5>
        <div className="d-flex align-items-center gap-3">
          <span className="filter_label">{t("filterNav.industry")}</span>
          <SelectUI
            options={selectOptionConverter(dropdownData?.IndustryOption)}
            selectedValue={selectedIndustryValue}
            value={filterStore.Industry}
            placeholder={t("filterNav.choose")}
            className="flex-grow-1"
          />
        </div>

        <div className="d-flex align-items-center gap-3 mt-2 mt-md-0 ">
          <span className="filter_label">
            {t("performanceReportText.response")}
          </span>
          <SelectUI
            options={reportTypeOptions}
            defaultValue={true}
            selectedValue={setSelectedPitchResponseValue}
            value={reportTypeOptions.find(
              (obj) => obj.value === filterStore.PitchResponse
            )}
            className="flex-grow-1"
          />
        </div>
      </div>
      <div>
        {showError && (
          <CustomError
            message={t(
              `performanceReportText.errorMessage.${
                filterStore.PitchResponse || "yes"
              }`
            )}
            icon={<img src={ErrorIcon} alt="error" />}
          />
        )}
      </div>
      <Row>
        {selectedGraph === "Bar Chart" ? (
          <Col md={12}>
            <div className="card-layout">
              <BarRecharts
                detailedBarData={convertBarChartNames(detailedBarData)}
                loading={isLoader.barchart}
                dataKeys={reportBarChartDatakeys}
              />
              <Circlebar
                data={updatedLineChartDataLabels}
                colors={ChartsColor}
              />
            </div>
          </Col>
        ) : selectedGraph === "Line Chart" ? (
          <Col md={12}>
            <div className="card-layout">
              <LineRecharts
                lineData={lineChartData}
                loading={isLoader.linechart}
                datakeys={reportLineChartDatakeys}
                dataLabels={updatedLineChartDataLabels}
                yAxisLabelName={t("barChartText.applicantRates")}
                isSmall
                // isSecondaryRange={filterStore.unit === "quarter" ? true : false}
              />
            </div>
          </Col>
        ) : (
          [
            "performanceReportText.yourApplicationRate",
            "performanceReportText.totalMarketAvg",
            "performanceReportText.industryAvg",
          ].map((result, i) => (
            <Col md={4} key={i}>
              <div className="card-layout h-100">
                <Row>
                  <Col>
                    <h5 className="align-flex-start">{t(result)}</h5>
                  </Col>
                </Row>
                <DonutChart
                  data={
                    result === "performanceReportText.yourApplicationRate"
                      ? companyData
                      : result === "performanceReportText.totalMarketAvg"
                      ? marketData
                      : industryData
                  }
                  colors={ChartsColor}
                  loading={
                    result === "performanceReportText.industryAvg"
                      ? isLoader.industrypiechart
                      : isLoader.piechart
                  }
                />

                <CircleLabel
                  data={companyData}
                  colors={ChartsColor}
                  pieChartNameConvention={pieChartNameConvention}
                />
              </div>
            </Col>
          ))
        )}
      </Row>
      <ApplicantDetailsTable
        handleSetLocationUsed={handleSetLocationUsed}
        locationStateUsed={locationStateUsed}
        getAllFlagC={getAllFlags.getAllFlagC}
        setGetAllFlags={setGetAllFlags}
      />
    </>
  );
};

export default PerformanceReport;
