import React, { useContext, useState } from "react";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
// import { DataGrid, gridClasses } from "@mui/x-data-grid";

import { UserContext } from "context/UserContext";
import ModalBox from "../../components/Modal/ModalBox";
import AddUserCard from "./AddUserCard";
import { convertGridColumnMenu } from "helpers/ReusableFunctions";

import UsersData from "../../mockdata/UsersData.json";
import DeleteUserSvg from "../../assets/svg/delete-user.svg";
import AddUserSvg from "../../assets/svg/add-user.svg";
import ActionSvg from "../../assets/svg/action-table.svg";
import { useTranslation } from "react-i18next";

const UserPermissions = ({ apiRef }) => {
  const { t } = useTranslation();
  const { language } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(UsersData);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 30,
      sortable: false,
    },
    {
      field: "user",
      headerName: "Users",
      width: 300,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      sortable: false,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      sortable: false,
    },
    {
      field: "industry",
      headerName: t("filterNav.industry"),
      width: 200,
      sortable: false,
    },
    {
      field: "role",
      headerName: t("timeToHire.role"),
      width: 200,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      sortable: false,
      renderCell: renderStatusCell,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: renderActionsCell,
    },
  ];

  const intialHiddenColumns = {
    id: false,
  };
  const getRowHeight = (params) => {
    return 90; // In pixels
  };

  function renderStatusCell(params) {
    return (
      <div
        className={params.value === "Active" ? "active-cell" : "inactive-cell"}
      >
        {params.value === "Active" ? "Active" : "Inactive"}
      </div>
    );
  }

  function renderActionsCell(params) {
    // Implement your custom action component or logic here
    return (
      <div className="action-svg">
        <img src={ActionSvg} alt="action" />
      </div>
    );
  }

  return (
    <div>
      {" "}
      <div className="card mt-4">
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <div className="action-button" onClick={handleShowModal}>
              <img src={AddUserSvg} alt="add user" />
              <div className="action-text">Add User</div>
            </div>
            <div className="action-button">
              <img src={DeleteUserSvg} alt="delete user" />
              <div className="action-text">Delete User</div>
            </div>
          </div>
          <div className="cstm-mui-datagrid" style={{ width: "100%" }}>
            <DataGridPro
              rows={[...userData].reverse()}
              columns={columns}
              initialState={{
                ...columns.initialState,
                columns: {
                  ...columns.initialState?.columns,
                  columnVisibilityModel: intialHiddenColumns,
                },
              }}
              checkboxSelection
              disableColumnFilter
              disableColumnHeaderSorting
              disableColumnReorder
              disableColumnMenu
              disableChildrenSorting
              disableColumnResize
              disableRowSelectionOnClick
              hideFooter
              getRowHeight={getRowHeight}
              sx={{
                [`.${gridClasses.columnSeparator} svg`]: {
                  visibility: "hidden",
                },
              }}
              apiRef={apiRef}
              localeText={convertGridColumnMenu(language)}
            />
          </div>
        </div>
      </div>
      <ModalBox
        show={showModal}
        onHide={handleCloseModal}
        title="Create New User"
        content={
          <AddUserCard
            UsersData={UsersData}
            userData={userData}
            setUserData={setUserData}
            setShowModal={setShowModal}
          />
        }
        size="lg"
      />
    </div>
  );
};

export default UserPermissions;
