import Submenu from "components/SubMenu/Submenu";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import JobDetailsTab from "./JobDetailsTab";
import {
  getJobDetails,
  getPitchedCandidates,
  getTargetCandidates,
} from "services/FetchApiData";
import TargetedCandidatesTab from "./TargetedCandidatesTab";
import PitchedCandidatesTab from "./PitchedCandidatesTab";
import { FilterContext } from "context/FilterContext";
import moment from "moment";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { UserContext } from "context/UserContext";
import ModalBox from "components/Modal/ModalBox";
import ProfileModalContent from "components/CandidateProfileModalContent/ProfileModalContent";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { uuidv4 } from "helpers/ReusableFunctions";

const JobDetailsModalBody = ({
  currentData = {},
  setCurrentData = () => {},
  loading = false,
  setLoading = () => {},
}) => {
  const { t } = useTranslation();
  const apiTargetCandidatesRef = useGridApiRef();
  const apiPitchedCandidatesRef = useGridApiRef();
  const dataFetch = useRef(false);
  const pitchedCandidatesFetch = useRef(false);
  const targetCandidatesFetch = useRef(false);
  const { language } = useContext(UserContext);
  const { filterStore, isInternalUser } = useContext(FilterContext);

  const TABS = {
    JOB_DETAILS: t("jobs.jobDetailsView"),
    TARGETED_CANDIDATES: t("jobs.targetedCandidates"),
    PITCHED_CANDIDATES: t("jobs.pitchedCandidates"),
  };

  const [basicActive, setBasicActive] = useState(TABS.JOB_DETAILS);
  const [pagination, setPagination] = useState({ limit: 100, offset: 0 });
  const [pitchedCandidates, setPitchedCandidates] = useState([]);
  const [targetCandidates, setTargetCandidates] = useState([]);
  const [isPitchedCandidatesLoading, setIsPitchedCandidatesLoading] =
    useState(false);
  const [isTargetCandidatesLoading, setIsTargetCandidatesLoading] =
    useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [candidateSelected, setCandidateSelected] = useState(null);

  const layoutProps = {
    language,
    isInternalUser,
    loading,
    basicActive,
    currentData,
    onRowClick: (data) => handleShowProfileModal(data),
  };

  const apiRef = useMemo(
    () =>
      basicActive === TABS.PITCHED_CANDIDATES
        ? apiPitchedCandidatesRef
        : apiTargetCandidatesRef,
    [
      TABS.PITCHED_CANDIDATES,
      basicActive,
      apiPitchedCandidatesRef,
      apiTargetCandidatesRef,
    ]
  );

  const fetchJobDetails = useCallback(
    async (position_id) => {
      if (!position_id || dataFetch.current) return;
      try {
        setLoading(true);
        dataFetch.current = true;
        const response = await getJobDetails(position_id);
        const jobData = response?.data?.data?.positions?.client_job_detail;
        setCurrentData((prev) => ({
          ...prev,
          ...jobData,
          description: jobData?.description
            ? JSON.parse(jobData?.description)
            : null,
          tech_stack: jobData?.tech_stack
            ? JSON.parse(jobData?.tech_stack)
            : null,
        }));
      } catch (error) {
        // Handle error case
        console.log(error);
      } finally {
        setLoading(false);
        dataFetch.current = false;
      }
    },
    [setCurrentData, setLoading]
  );

  const fetchPitchedCandidates = useCallback(async (position_id) => {
    if (!position_id || pitchedCandidatesFetch.current) return;
    try {
      setIsPitchedCandidatesLoading(true);
      pitchedCandidatesFetch.current = true;
      const response = await getPitchedCandidates(position_id, language);
      setPitchedCandidates(
        response?.data?.data?.positions?.pitch_list?.data?.map((el) => ({
          ...el,
          id: el?.candidate_id || uuidv4(),
          age: el?.birthday ? moment().diff(el?.birthday, "years") : "",
        }))
      );
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setIsPitchedCandidatesLoading(false);
      pitchedCandidatesFetch.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTargetCandidates = useCallback(
    async (position_id, paginationValues = pagination) => {
      if (!position_id || targetCandidatesFetch.current) return;
      try {
        setIsTargetCandidatesLoading(true);
        targetCandidatesFetch.current = true;
        const response = await getTargetCandidates(
          position_id,
          paginationValues,
          language
        );
        setTargetCandidates((prev) => [
          ...(paginationValues?.offset === 0 ? [] : prev),
          ...response?.data?.data?.positions?.target_list?.data?.map((el) => ({
            ...el,
            id: el?.candidate_id || uuidv4(),
            age: el?.birthday ? moment().diff(el?.birthday, "years") : "",
          })),
        ]);
        setPagination({
          ...paginationValues,
          count: response?.data?.data?.positions?.target_list?.count || 0,
          hasMore: response?.data?.data?.positions?.target_list?.has_more,
        });
      } catch (error) {
        // Handle error case
        console.log(error);
      } finally {
        setIsTargetCandidatesLoading(false);
        targetCandidatesFetch.current = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePaginationModelChange = ({ pageSize, page }) => {
    let isPageSizeChanged = pagination?.page >= page;
    let newPage = isPageSizeChanged ? 0 : page;
    const newPaging = {
      ...pagination,
      page: newPage,
      limit: pageSize,
      offset: isPageSizeChanged ? 0 : pageSize * page,
    };
    apiTargetCandidatesRef.current.setPage(newPage);
    setPagination(newPaging);
    fetchTargetCandidates(currentData?.position_id, newPaging);
  };

  useEffect(() => {
    setTargetCandidates([]);
    apiTargetCandidatesRef.current.setPaginationModel({
      pageSize: pagination?.limit,
      page: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData?.position_id]);

  useEffect(() => {
    fetchJobDetails(currentData?.position_id);
    fetchPitchedCandidates(currentData?.position_id);
    fetchTargetCandidates(currentData?.position_id);
  }, [
    currentData?.position_id,
    fetchJobDetails,
    fetchPitchedCandidates,
    fetchTargetCandidates,
  ]);

  const tabs = [
    {
      title: TABS.JOB_DETAILS,
      content: (
        <JobDetailsTab
          {...layoutProps}
          dateRange={`${moment(filterStore?.startDate).format(
            "D MMM YYYY"
          )} - ${moment(filterStore?.endDate).format("D MMM YYYY")}`}
          totalPitchedCandidates={pitchedCandidates?.length}
          totalTargetCandidates={pagination?.count}
          openCandidateTargetTab={() =>
            setBasicActive(TABS.TARGETED_CANDIDATES)
          }
        />
      ),
    },
    {
      title: TABS.TARGETED_CANDIDATES,
      content: (
        <TargetedCandidatesTab
          {...layoutProps}
          apiRef={apiTargetCandidatesRef}
          loading={isTargetCandidatesLoading}
          targetCandidates={targetCandidates}
          pagination={pagination}
          onPaginationModelChange={handlePaginationModelChange}
        />
      ),
    },
    {
      title: TABS.PITCHED_CANDIDATES,
      content: (
        <PitchedCandidatesTab
          {...layoutProps}
          apiRef={apiPitchedCandidatesRef}
          loading={isPitchedCandidatesLoading}
          pitchedCandidates={pitchedCandidates}
        />
      ),
    },
  ];

  const handleShowProfileModal = (data) => {
    setCandidateSelected(data);
    setShowProfileModal(true);
  };

  const handleCloseModal = () => {
    setShowProfileModal((current) => !current);
  };

  return (
    <>
      <Submenu
        tabs={tabs}
        basicActive={basicActive}
        setBasicActive={setBasicActive}
      />
      <ModalBox
        show={showProfileModal}
        onHide={handleCloseModal}
        content={
          apiRef?.current?.state && (
            <ProfileModalContent
              isCandidatesAccess
              closeModal={handleCloseModal}
              current={candidateSelected}
              title={t("cardViewText.profileView")}
              apiRef={apiRef}
            />
          )
        }
      />
    </>
  );
};

export default memo(JobDetailsModalBody);
