import { Col, Row } from "react-bootstrap";
import { memo } from "react";

import { ChartsColor } from "helpers/Constant";

import LineRecharts from "components/Charts/LineRecharts";

const QualityStats = ({ lineData = [], metricsData = [] }) => {
  const reportDataKeys = ["my", "all"];

  const reportDataLabels = [
    {
      name: "My Ratio",
    },
    {
      name: "Company Ratio",
    },
  ];

  return (
    <div className="card-layout w-100">
      <Row className="gap-2 gap-md-0 g-4">
        <Col md={2}>
          <div
            className="card-layout h-100 w-100 d-flex flex-md-column align-items-center gap-2 justify-content-around rounded-5 m-0"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
          >
            {reportDataKeys.map((key, i) => (
              <div
                key={i}
                className={`d-flex flex-column justify-content-center align-items-center`}
              >
                <h1
                  className="card-title fw-bolder"
                  style={{ color: ChartsColor[i] }}
                >
                  {metricsData[i]?.data || 0}
                </h1>
                <h6 className="metrics-label text-center lh-sm">
                  {key === "my" ? "My Ratio" : "Company Ratio"}
                </h6>
              </div>
            ))}
          </div>
        </Col>
        <Col md={10}>
          <div
            className={`card-layout h-100 w-100 m-0`}
            style={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
          >
            <LineRecharts
              lineData={lineData}
              datakeys={reportDataKeys}
              dataLabels={reportDataLabels}
              isYAxisContainsPercentData={false}
              yAxisLabelName="Quality Ratio"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default memo(QualityStats);
