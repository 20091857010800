import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import FilterNav from "components/FilterNav/FilterNav";
import ModalBox from "components/Modal/ModalBox";
import CustomFilter from "components/Reports/CustomFilter";
import JobDetailsModalContent from "components/JobDetailsModalContent/JobDetailsModalContent";
import { FilterContext } from "context/FilterContext";

import { UserContext } from "context/UserContext";
import { ChartsColor, maximumExportPaging, PAGES } from "helpers/Constant";
import {
  convertGridColumnMenu,
  copyToClipboard,
  delay,
  getColumnsAfterChangeOrder,
  getCurrentPageAllFiltersFromFilterStore,
} from "helpers/ReusableFunctions";
import ExportModal from "pages/PipelineReport/components/ExportModal";
import React, { useContext, useEffect, useRef, useState } from "react";
import { getJobList } from "services/FetchApiData";
import Snackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import DashboardCard from "components/Dashboard/DashboardCard";
import { useTranslation } from "react-i18next";

const JobList = () => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();

  const {
    filterStore,
    applyFilterFlags: { applyFilterInJobs },
    setLastAppliedFilterstore,
    setIsDisableApplyButton,
    isInternalUser,
    Clients,
  } = useContext(FilterContext);
  const { language, userGroup, loggedInUserData } = useContext(UserContext);

  const sortableColumn = true;
  const pinnableColumn = true;

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const columns = [
    {
      field: "total_searches",
      headerName: t("jobs.searchesRan"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    ...(isInternalUser
      ? [
          {
            field: "client_name",
            headerName: "Client Name",
            width: 150,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
        ]
      : []),
    {
      field: "position_name",
      headerName: t("jobs.jobNameEn"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "position_name_ja",
      headerName: t("jobs.jobNameJa"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "candidate_targets",
      headerName: t("jobs.candidateTargets"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "role",
      headerName: t("jobs.jobCategory"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "focus",
      headerName: t("jobs.speciality"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    ...(isInternalUser
      ? [
          {
            field: "industry",
            headerName: t("filterNav.industry"),
            width: 150,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
          {
            field: "company_stage",
            headerName: "Company Stage",
            width: 150,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
        ]
      : []),
    {
      field: "remote_policy",
      headerName: t("jobs.remoteStatus"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "location",
      headerName: t("jobs.location"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "position_id",
      headerName: "Job ID",
      width: 280,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: ({ value }) => (
        <div
          className="MuiDataGrid-cellContent cursor-pointer text-decoration-underline"
          title="Click to Copy"
          onClick={(e) => handleCopyJobId(e, value)}
        >
          {value}
        </div>
      ),
    },
  ];

  const isJobsFetch = useRef(false);
  const [jobListData, setJobListData] = useState([]);
  const [metricsData, setMetricsData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 100, offset: 0 });
  const [showExportModal, setShowExportModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [initialRenderFlag, setInitialRenderFlag] = useState(true);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [columnsOrder, setColumnsOrder] = useState(columns);
  const [hiddenColumns, setHiddenColumns] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    setInitialRenderFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialRenderFlag) {
      setColumnsOrder((prev) => {
        const temp = prev.map((item) =>
          getColumnsAfterChangeOrder(item, columns)
        );
        return temp;
      });
    }
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (!initialRenderFlag) {
      if (pagination?.offset === 0) {
        fetchJobListData();
      } else {
        apiRef.current.setPage(0);
      }
    }
    return () => setJobListData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applyFilterInJobs,
    initialRenderFlag,
    language,
    filterStore.startDate,
    filterStore.endDate,
  ]);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const handleShowExportModal = () => {
    setShowExportModal((current) => !current);
  };

  const handleCopyJobId = (e, value) => {
    e.stopPropagation();
    copyToClipboard(value);
    handleOpenSnackbar();
  };

  const fetchJobListData = async (paginationValues = pagination) => {
    if (isLoadingTableData || isJobsFetch.current) return;
    try {
      isJobsFetch.current = true;
      setIsLoadingTableData(true);
      setIsDisableApplyButton(true);
      const response = await getJobList(
        getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.JOBS
        ),
        Clients,
        language,
        paginationValues
      );
      setJobListData([
        ...(paginationValues?.offset === 0 ? [] : jobListData),
        ...(response?.data?.data?.positions?.client_jobs?.data ?? []),
      ]);
      setMetricsData(() => {
        const metricsResponse =
          response?.data?.data?.dashboard?.client_jobs_counts;
        return [
          {
            stage: t("jobs.openJobs"),
            count: metricsResponse?.total_positions || 0,
          },
          {
            stage: t("jobs.totalSearchesConducted"),
            count: metricsResponse?.positions_worked || 0,
          },
          {
            stage: t("jobs.activeSearches"),
            count: metricsResponse?.positions_working || 0,
          },
        ];
      });
      setPagination({
        ...paginationValues,
        count: response?.data?.data?.positions?.client_jobs?.count || 0,
        hasMore: response?.data?.data?.positions?.client_jobs?.has_more,
      });
      setLastAppliedFilterstore(filterStore);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingTableData(false);
      setIsDisableApplyButton(false);
      isJobsFetch.current = false;
    }
  };

  const handleShowDetailsModal = () => {
    setShowDetailsModal((current) => !current);
  };

  const handlePaginationModelChange = async ({ pageSize, page }) => {
    let isPageSizeChanged = pagination?.page >= page;
    let newPage = isPageSizeChanged ? 0 : page;
    const newPaging = {
      ...pagination,
      page: newPage,
      limit: pageSize,
      offset: isPageSizeChanged ? 0 : pageSize * page,
    };
    apiRef.current.setPage(newPage);
    setPagination(newPaging);
    await fetchJobListData(newPaging);
  };

  const handleExport = async () => {
    await handlePaginationModelChange(maximumExportPaging);
    await apiRef.current.setPaginationModel(maximumExportPaging);
    await delay(1000);
  };

  return (
    <>
      <h4>{t("dashboardText.jobs")}</h4>
      <FilterNav />
      <DashboardCard mdSize={12} isLoading={isLoadingTableData}>
        <div className="active-pipeline-count mb-5">
          <div className="d-flex justify-content-center gap-4 row">
            {metricsData.map((el, index) => (
              <div className="text-center col" key={index}>
                <h1
                  className="card-title"
                  style={{ color: ChartsColor[index % 3] }}
                >
                  {el.count}
                </h1>
                <h5> {el?.stage} </h5>
              </div>
            ))}
          </div>
        </div>
      </DashboardCard>
      <div className="card mt-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="flex-shrink-1">{t("jobs.listOfAllJobs")}</h5>
            <div className="d-flex gap-0 flex-shrink-0">
              {(userGroup.includes("external") ||
                (userGroup.includes("internal") &&
                  loggedInUserData.is_admin)) && (
                <div
                  className="action-button align-self-center"
                  onClick={handleShowExportModal}
                >
                  <div className="m-0">{t("performanceReportText.export")}</div>
                </div>
              )}
            </div>
          </div>
          <div
            className="cstm-mui-datagrid"
            style={{ height: 500, width: "100%" }}
          >
            <DataGridPro
              loading={isLoadingTableData}
              rows={jobListData}
              rowCount={pagination?.count}
              pagination
              pageSizeOptions={[50, 100, 500, 1000]}
              onPaginationModelChange={handlePaginationModelChange}
              columns={columnsWithOperators}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
                pagination: {
                  backIconButtonProps: {
                    disabled: isLoadingTableData || !pagination?.offset,
                  },
                  nextIconButtonProps: {
                    disabled: isLoadingTableData || !pagination?.hasMore,
                  },
                },
              }}
              getRowId={(row) => row.position_id}
              getRowClassName={({ row }) =>
                row?.active_searches > 0 ? "bg-lightGreen" : ""
              }
              onColumnOrderChange={(c) => {
                setColumnsOrder((prevCols) => {
                  const newCols = [...prevCols];
                  newCols.splice(c.oldIndex, 1);
                  newCols.splice(c.targetIndex, 0, c.column);
                  return newCols;
                });
              }}
              initialState={{
                ...columns.initialState,
                sorting: {
                  sortModel: [{ field: "company", sort: "asc" }],
                },
                columns: columns.initialState?.columns,
              }}
              onRowClick={(params) => {
                setSelectedRow(params.row);
                handleShowDetailsModal();
              }}
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              onColumnVisibilityModelChange={(hideColumns) => {
                const hideColsName = Object.entries(hideColumns)
                  .filter(([_key, value]) => value === false)
                  ?.map((col) => col[0]);
                setHiddenColumns(hideColsName);
              }}
              rowsLoadingMode={"server"}
            />
          </div>
        </div>
      </div>
      <ModalBox
        show={showExportModal}
        onHide={handleShowExportModal}
        title="Export File"
        content={
          <ExportModal
            fileName="Job List"
            setShowModal={setShowExportModal}
            columns={columnsOrder}
            apiRef={apiRef}
            hiddenColumns={hiddenColumns}
            onExport={handleExport}
          />
        }
      />
      <ModalBox
        show={showDetailsModal}
        onHide={handleShowDetailsModal}
        content={
          apiRef?.current?.state && (
            <JobDetailsModalContent
              title={t("jobs.jobDetailsView")}
              closeModal={handleShowDetailsModal}
              current={selectedRow}
              apiRef={apiRef}
            />
          )
        }
      />
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleOpenSnackbar}
        message="Job ID Copied"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleOpenSnackbar}
          >
            <IoIosClose />
          </IconButton>
        }
      />
    </>
  );
};

export default JobList;
