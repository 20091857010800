import FilterNav from "components/FilterNav/FilterNav";
import TopFilters from "pages/PipelineReport/components/TopFilters";
import React, { useContext, useEffect, useRef, useState } from "react";
import QualityStats from "./components/QualityStats";
import { FilterContext } from "context/FilterContext";
import { getQualityReportData } from "services/FetchApiData";
import {
  calculateMetricsForQualityReport,
  getCurrentPageAllFiltersFromFilterStore,
} from "helpers/ReusableFunctions";
import { PAGES } from "helpers/Constant";
import { isEmpty } from "lodash";

const QualityReport = () => {
  const isDataFetch = useRef(false);

  const {
    filterStore,
    loading,
    setLoading,
    applyFilterFlags: { applyFilterInQualityReport },
    isInternalUser,
  } = useContext(FilterContext);

  const [initialRenderFlag, setInitialRenderFlag] = useState(true);
  const [qualityMetricsData, setQualityMetricsData] = useState([]);
  const [qualityLinesData, setQualityLinesData] = useState([]);

  const fetchDomainHealthData = async () => {
    if (
      loading ||
      isEmpty(filterStore?.StageManagers1?.value) ||
      isEmpty(filterStore?.StageManagers2?.value)
    )
      return;
    try {
      isDataFetch.current = true;
      setLoading(true);
      const response = await getQualityReportData(
        getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.QUALITY_REPORT
        )
      );
      const { metricsData, lineGraphData } =
        calculateMetricsForQualityReport(response);
      setQualityMetricsData(metricsData);
      setQualityLinesData(lineGraphData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      isDataFetch.current = false;
    }
  };

  const resetTimeToHireReportsAllData = () => {
    setQualityMetricsData([]);
    setQualityLinesData([]);
  };

  useEffect(() => {
    setInitialRenderFlag(false);
  }, []);

  useEffect(() => {
    if (!initialRenderFlag) {
      fetchDomainHealthData();
    }
    return () => resetTimeToHireReportsAllData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialRenderFlag,
    applyFilterInQualityReport,
    filterStore.StageManagers1,
    filterStore.StageManagers2,
    filterStore.count,
    filterStore.startDate,
    filterStore.endDate,
    filterStore.unit,
  ]);

  return (
    <>
      <h4>Quality Report</h4>
      <FilterNav />
      <TopFilters
        resetTimeToHireReportsAllData={resetTimeToHireReportsAllData}
      />
      <QualityStats
        loading={loading}
        metricsData={qualityMetricsData}
        lineData={qualityLinesData}
        isInternalUser={isInternalUser}
      />
    </>
  );
};

export default QualityReport;
