import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Line,
  YAxis,
  Tooltip,
  Legend,
  XAxis,
  CartesianGrid,
  Brush,
  ComposedChart,
  Text,
  ResponsiveContainer,
} from "recharts";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { FaExpand } from "react-icons/fa";

import CircleLabel from "../UI/Circle/CircleLabel";
import { FilterContext } from "context/FilterContext";
import { ChartsColor } from "helpers/Constant";
import useWindowSize from "hooks/useWindowSize";
import { numberWithCommas } from "helpers/ReusableFunctions";
import { useTranslation } from "react-i18next";

const successRatioChartColors = [
  ChartsColor[0],
  ChartsColor[1],
  ChartsColor[2],
];
const submissionRatioChartColors = ["#10c1e5", "#e510be", "#152de5"];

const CustomTooltip = ({
  active,
  payload,
  isYAxisContainsPercentData,
  dataLabels,
}) => {
  const { t } = useTranslation();
  function getKeyValue(key, value) {
    return `${key} : ${value}${isYAxisContainsPercentData ? "%" : ""}`;
  }

  if (active && payload && payload.length) {
    const start = JSON.parse(payload[0].payload.range).interval_start;
    const end = JSON.parse(payload[0].payload.range).interval_end;

    const renderDetailSubmissionDetailForSuccessKpi = (item) => {
      if (
        window.location.pathname === "/successkpireport" &&
        item.payload.ratioType === "submission"
      ) {
        return (
          <div className="d-flex flex-column mt-1">
            <p className="mb-0 text-muted">
              {`${t("successKpi.success")} : ${
                item.payload[item.dataKey + t("successKpi.success")]
              }`}
            </p>
            <p className="mb-0 text-muted">
              {`Failure : ${item.payload[item.dataKey + "Failure"]}`}
            </p>
            <p className="mb-0 text-muted">
              {`Pending : ${item.payload[item.dataKey + "Pending"]}`}
            </p>
          </div>
        );
      } else {
        <></>;
      }
    };

    return (
      <div className="bg-white d-flex flex-column gap-2 p-2 border">
        <span>
          {moment(start).format("D MMM YYYY")}
          {" - "}
          {moment(end).format("D MMM YYYY")}
        </span>
        {payload.map((item, i) => {
          if (item.dataKey === "company" || item.dataKey === "my") {
            return (
              <React.Fragment key={i}>
                <span style={{ color: item.stroke }}>
                  {numberWithCommas(
                    getKeyValue(dataLabels[0].name, item?.value)
                  )}
                </span>
                {renderDetailSubmissionDetailForSuccessKpi(item)}
              </React.Fragment>
            );
          }
          if (item.dataKey === "market" || item.dataKey === "all") {
            return (
              <React.Fragment key={i}>
                <span style={{ color: item.stroke }}>
                  {numberWithCommas(
                    getKeyValue(dataLabels[1].name, item?.value)
                  )}
                </span>
                {renderDetailSubmissionDetailForSuccessKpi(item)}
              </React.Fragment>
            );
          }
          if (item.dataKey === "industry") {
            return (
              <React.Fragment key={i}>
                <span style={{ color: item.stroke }}>
                  {numberWithCommas(
                    getKeyValue(dataLabels[2].name, item?.value)
                  )}
                </span>
                {renderDetailSubmissionDetailForSuccessKpi(item)}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={i}>
              <span style={{ color: item.stroke }}>
                {numberWithCommas(getKeyValue(dataLabels[i].name, item?.value))}
              </span>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  return null;
};

const CustomizedDot = (props) => {
  const { cx, cy, payload } = props;
  const { filterStore } = useContext(FilterContext);

  if (payload?.isSecondary === false && filterStore.count <= 2) {
    return (
      <svg x={cx - 4} y={cy - 4} width={8} height={8} fill="white">
        <g transform="translate(4 4)">
          <circle r="4" fill="#424242" />
          <circle r="2" fill="white" />
        </g>
      </svg>
    );
  }
};

const LineRecharts = ({
  lineData = [],
  loading = false,
  datakeys = [],
  dataLabels,
  isExpandAllowed = false,
  isYAxisContainsPercentData = true,
  openModal = (index) => {},
  isOpenModal = false,
  selectedLineIndex = 0,
  yAxisLabelName = "",
  isSmall = false,
  // isSecondaryRange = false,
}) => {
  const location = useLocation();
  const { filterStore } = useContext(FilterContext);
  const windowSize = useWindowSize();
  const [brushSize, setBrushSize] = useState(2);

  const showAxisPointsDiagonally = isSmall && filterStore?.count >= 2;
  const showCenteredAxisLabel = [
    "/successkpireport",
    "/timetohirereport",
  ].includes(window.location.pathname);

  const showDashBeforeYear = ["/successkpireport"].includes(location.pathname);
  const primaryTickFormatter = (val) => {
    if (val) {
      const start = moment(JSON.parse(val).interval_start);
      const end = moment(JSON.parse(val).interval_end);

      if (end.diff(start, "months") > 1) {
        return `${start.format(
          `MMM ${showDashBeforeYear ? "[']" : ""}YY`
        )} to ${end.format(`MMM ${showDashBeforeYear ? "[']" : ""}YY`)}`;
      }
      if (
        (filterStore.count > 2 && filterStore.unit === "month") ||
        filterStore.unit === "quarter"
      ) {
        return end.format("MMM YYYY");
      }
      return end.format(`D MMM ${showDashBeforeYear ? "[']" : ""}YY`);
    }
    return "";
  };

  const tickStyleFormatter = (e) => {
    const {
      payload: { value },
    } = e;
    const start = moment(JSON.parse(value).interval_start);
    const end = moment(JSON.parse(value).interval_end);

    const highlightedTick =
      ((end.diff(start, "days") > 27 && filterStore.unit === "month") ||
        (end.diff(start, "months") > 1 && filterStore.unit === "quarter") ||
        (end.diff(start, "months") > 6 && filterStore.unit === "year")) &&
      filterStore.count <= 2;

    const color = highlightedTick ? "#272727" : "gray";
    e["fill"] = color;
    e["dx"] = showAxisPointsDiagonally ? -30 : -3;

    if (highlightedTick) {
      return (
        <Text {...e} style={{ fontSize: "17px" }}>
          {filterStore.unit === "month"
            ? end.format(`MMM ${showDashBeforeYear ? "[']" : ""}YY`)
            : start.format(`MMM ${showDashBeforeYear ? "[']" : ""}YY`) +
              " to " +
              end.format(`MMM ${showDashBeforeYear ? "[']" : ""}YY`)}
        </Text>
      );
    }
    if (
      filterStore.unit === "year" ||
      (filterStore.unit === "quarter" && filterStore.count > 2)
    ) {
      return (
        <Text {...e}>
          {start.format(`MMM ${showDashBeforeYear ? "[']" : ""}YY`) +
            " to " +
            end.format(`MMM ${showDashBeforeYear ? "[']" : ""}YY`)}
        </Text>
      );
    }
    if (filterStore.count > 2 && filterStore.unit === "month") {
      return (
        <Text {...e}>
          {end.format(`MMM ${showDashBeforeYear ? "[']" : ""}YY`)}
        </Text>
      );
    }
    return (
      <Text {...e}>
        {end.format(`D MMM ${showDashBeforeYear ? "[']" : ""}YY`)}
      </Text>
    );
  };

  const generateYAxisPoints = () => {
    const numbers = lineData
      .map((item) => Object.values(item))
      .flat()
      .filter((item) => typeof item === "number");

    const max = Math.max(...numbers);
    const min = Math.min(...numbers);
    const divisor = Number(
      `1${Array.from({ length: max?.toString()?.length - 1 })?.reduce(
        (total) => (total += "0"),
        ""
      )}`
    );

    const maxVal = Math.ceil(max / divisor);
    const minVal = Math.ceil(min / divisor) - 1;
    const tickMax = (maxVal * divisor === max ? maxVal + 1 : maxVal) * divisor;
    const tickMin = minVal > 0 ? minVal * divisor : 0;

    const tickArray = Array.from(
      { length: (tickMax - tickMin) / divisor + 1 },
      (_value, index) => {
        const val = tickMax > 80 ? 20 + index * 2 * 10 : tickMin + index * 10;
        let maxTickPossible = Math.max(
          ...lineData.map((line) => {
            if (datakeys.includes("my") && datakeys.includes("all")) {
              return Math.max(line?.all || 0, line?.my || 0);
            } else if (
              datakeys.includes("company") &&
              datakeys.includes("market") &&
              datakeys.includes("industry")
            ) {
              return Math.max(
                line?.company || 0,
                line?.market || 0,
                line?.industry || 0
              );
            } else if (
              datakeys.includes("emailsSent") &&
              datakeys.includes("leads")
            ) {
              return Math.max(line.emailsSent, line.leads);
            } else if (
              datakeys.includes("positiveReplies") &&
              datakeys.includes("meetings") &&
              datakeys.includes("pitches") &&
              datakeys.includes("targetPitches")
            ) {
              return Math.max(
                line.positiveReplies,
                line.meetings,
                line.pitches,
                line.targetPitches
              );
            } else if (
              datakeys.includes("percentPositive") &&
              datakeys.includes("percentMeetings")
            ) {
              return Math.max(line.percentPositive, line.percentMeetings);
            } else if (
              datakeys.includes("pitchesRatio") &&
              datakeys.includes("targetPitchesRatio")
            ) {
              return Math.max(line.pitchesRatio, line.targetPitchesRatio);
            } else if (datakeys.includes("percentReplied")) {
              return line.percentReplied;
            } else {
              return line?.company || 0;
            }
          })
        );
        const yLimit = isYAxisContainsPercentData ? 100 : maxTickPossible;
        if (
          (!isYAxisContainsPercentData && tickMax - tickMin > 100) ||
          val > yLimit
        ) {
          return null;
        }
        return val;
      }
    );

    return [0, ...tickArray];
  };

  useEffect(() => {
    if (windowSize >= 1440) {
      setBrushSize(7);
    } else if (windowSize >= 1024 && windowSize < 1440) {
      setBrushSize(6);
    } else if (windowSize >= 768 && windowSize < 1024) {
      setBrushSize(4);
    } else if (windowSize >= 480 && windowSize < 768) {
      setBrushSize(3);
    } else {
      setBrushSize(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isOpenModal, windowSize]);

  return (
    <>
      <div
        style={{
          height: !loading && lineData.length > 1 && !isSmall ? 300 : 250,
        }}
        className={`${
          loading ? "d-grid" : ""
        } line-recharts position-relative ${
          filterStore.count < 1 || lineData.length < 1
            ? ""
            : "d-flex justify-content-center w-100 mt-2"
        }`}
      >
        {!loading && lineData.length > 1 && isExpandAllowed && (
          <button
            className="btn btn-sm rounded-circle color-dark position-absolute shadow-none bg-transparent "
            style={{
              top: "-22px",
              right: "-22px",
              cursor: "pointer",
            }}
            onClick={() => openModal(selectedLineIndex)}
          >
            <FaExpand className="fw-bolder fs-6" />
          </button>
        )}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center p-4">
            <Spinner animation="border" />
          </div>
        ) : (filterStore.count < 1 &&
            !["/billingsreport", "/meetingconversionreport"].includes(
              location.pathname
            )) ||
          lineData.length === 1 ? (
          <div className="text-muted">No data to compare</div>
        ) : lineData.length < 1 ? (
          <div className="text-muted">No Data Found</div>
        ) : (
          <ResponsiveContainer>
            <ComposedChart
              height={
                !loading &&
                lineData.length > 1 &&
                (["/performancereport", "/timetohirereport"].includes(
                  location.pathname
                ) ||
                  (["/successkpireport"].includes(location.pathname) &&
                    isOpenModal))
                  ? 300
                  : location.pathname === "/successkpireport"
                  ? 200
                  : 250
              }
              data={lineData}
              margin={{
                ...(location.pathname !== "/successkpireport"
                  ? {
                      right: 15,
                      left: 15,
                      top: 15,
                      bottom: 15,
                    }
                  : {
                      right: 10,
                      left: 10,
                      top: 10,
                      bottom: 10,
                    }),
              }}
            >
              <CartesianGrid />
              <XAxis
                xAxisId="0"
                axisType="radiusAxis"
                // label={{
                //   value: "Date Ranges",
                //   position: "bottom",
                //   offset: filterStore.unit === "quarter" ? 60 : 35,
                // }}
                angle={showAxisPointsDiagonally ? -45 : 0}
                dataKey={"range"}
                axisLine={false}
                tickFormatter={(val) => primaryTickFormatter(val)}
                tickMargin={showAxisPointsDiagonally ? 33 : 5}
                minTickGap={8}
                allowDataOverflow={true}
                tick={(e) => tickStyleFormatter(e)}
                height={showAxisPointsDiagonally ? 90 : 40}
              />
              <YAxis
                label={{
                  value: yAxisLabelName,
                  angle: -90,
                  position: showCenteredAxisLabel
                    ? "insideLeft"
                    : "insideBottomLeft",
                }}
                tickMargin={6}
                tickFormatter={(value) =>
                  `${value}${isYAxisContainsPercentData ? "%" : ""}`
                }
                ticks={generateYAxisPoints()}
              />

              <Tooltip
                animationEasing="ease-in"
                content={
                  <CustomTooltip
                    isYAxisContainsPercentData={isYAxisContainsPercentData}
                    dataLabels={dataLabels}
                  />
                }
              />
              <Legend />
              {datakeys.map((datakey, i) => (
                <Line
                  key={i}
                  strokeWidth={2}
                  dataKey={datakey}
                  stroke={
                    location.pathname === "/successkpireport"
                      ? lineData[0].ratioType === "success"
                        ? successRatioChartColors[i]
                        : submissionRatioChartColors[i]
                      : ChartsColor[i]
                  }
                  activeDot={{ r: 4 }}
                  isAnimationActive={false}
                  // connectNulls
                  dot={<CustomizedDot />}
                />
              ))}
              {isSmall &&
                (["/performancereport", "/timetohirereport"].includes(
                  location.pathname
                )
                  ? lineData.length > brushSize && (
                      <Brush
                        // startIndex={0}
                        travellerWidth={0}
                        endIndex={brushSize}
                        // data={lineData}
                        // dataKey={"range"}
                        height={15}
                        y={280}
                      />
                    )
                  : lineData.length > 4 && (
                      <Brush
                        endIndex={3}
                        height={15}
                        y={
                          location.pathname === "/successkpireport" &&
                          isOpenModal
                            ? 280
                            : 230
                        }
                        travellerWidth={0}
                      />
                    ))}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>

      {!loading && lineData.length > 1 && (
        <CircleLabel
          data={dataLabels}
          colors={
            location.pathname === "/successkpireport"
              ? lineData[0].ratioType === "success"
                ? successRatioChartColors
                : submissionRatioChartColors
              : ChartsColor
          }
        />
      )}
    </>
  );
};

export default LineRecharts;
