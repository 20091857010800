import { Col, Row } from "react-bootstrap";

import {
  cleanObject,
  formatDate,
  reformatString,
} from "helpers/ReusableFunctions";
import CopyBtn from "components/UI/CopyBtn/CopyBtn";

const QnABox = ({
  isInternalUser,
  questions = {},
  isInsideAccordion = false,
}) => {
  questions = { ...cleanObject(questions) };

  return Object.keys(questions).length ? (
    Object.keys(questions).map(
      (val, ind) =>
        !!questions[val]?.length && (
          <div key={ind} className="mb-3">
            <p className="profile_section-title m-1">
              {reformatString(val) + " Questions"}
            </p>
            <div className="card mt-2">
              <div className="card-body p-0 ">
                {questions[val]
                  .sort(
                    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                  )
                  .map((queObj, ind, arr) => (
                    <Row key={queObj.id} className="g-0 position-relative">
                      <Col className="my-2 mx-3">
                        <Row className="row-12">
                          <p className="profile_subsection-dim_title mb-1 text-wrap lh-sm pe-5">
                            {queObj.question}
                          </p>
                        </Row>
                        <Row className="justify-content-between mt-1 w-100 gap-1">
                          <Col
                            className="m-0 col-12 col-lg-9"
                            style={{
                              maxHeight: "180px",
                              overflowY: "auto",
                              textAlign: "justify",
                              textJustify: "inter-word",
                            }}
                          >
                            <p className="profile_subsection-body mb-0 text-wrap">
                              {queObj.text}
                            </p>
                          </Col>
                          <Col className="mt-1 d-flex flex-column align-items-end w-100">
                            <p className="profile_subsection-body mb-0">
                              {formatDate(queObj?.updated_at)}
                            </p>
                            {queObj?.last_updated_by && (
                              <p className="profile_subsection-body mb-0">
                                {isInternalUser
                                  ? `${queObj?.last_updated_by?.first_name} ${queObj?.last_updated_by?.last_name}`
                                  : "ExecutiveSearch.AI"}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <div className="position-absolute top-0 end-0 mt-2 me-3">
                          <CopyBtn text={queObj.question} />
                        </div>
                      </Col>
                      {arr.length - 1 !== ind && <hr className="m-0" />}
                    </Row>
                  ))}
              </div>
            </div>
          </div>
        )
    )
  ) : isInsideAccordion ? (
    <p className="text-center m-2">No Questions Found !</p>
  ) : (
    <></>
  );
};

export default QnABox;
