import { Col, Row } from "react-bootstrap";
import { useContext } from "react";

import { ChartsColor } from "helpers/Constant";

import LineRecharts from "components/Charts/LineRecharts";
import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";
import { useTranslation } from "react-i18next";

const TimeToHireStats = ({
  lineData = [],
  loading = false,
  metricsData = [],
  isInternalUser = false,
  hideLineChart = false,
}) => {
  const { t } = useTranslation();
  const externalReportDataKeys = ["company", "market", "industry"];
  const internalReportDataKeys = ["my", "all"];
  const externalReportDataLabels = [
    {
      name: "Company Average Time",
    },
    {
      name: t("timeToHire.marketAverageTime"),
    },
    {
      name: t("timeToHire.industryAverageTime"),
    },
  ];

  const internalReportDataLabels = [
    {
      name: t("timeToHire.averageTime"),
    },
    {
      name: "Company Average Time",
    },
  ];

  const { Clients, isPresentationEnabled } = useContext(FilterContext);
  const { User, userGroup, loggedInUserData } = useContext(UserContext);

  const finalReportDataKeys = isInternalUser
    ? internalReportDataKeys
    : externalReportDataKeys;

  const finalReportDataLabels = isInternalUser
    ? internalReportDataLabels
    : externalReportDataLabels;

  const updatedLineChartDataLabels = finalReportDataLabels.map((labelObj) => {
    if (labelObj.name === "Company Average Time") {
      if (
        userGroup.includes("internal") &&
        isPresentationEnabled &&
        Clients.value
      ) {
        return {
          name: `${Clients?.label} ${t("timeToHire.averageTime")}`,
        };
      } else if (
        User?.signInUserSession?.idToken?.payload?.["custom:alt_org_name"]
      ) {
        return {
          name: `${
            User.signInUserSession.idToken.payload["custom:alt_org_name"]
          } ${t("timeToHire.averageTime")}`,
        };
      } else if (loggedInUserData?.organization?.name) {
        return {
          name: `${loggedInUserData.organization.name} ${t(
            "timeToHire.averageTime"
          )}`,
        };
      }
      return labelObj;
    }
    return labelObj;
  });

  function getCustomKeyName(dataKey) {
    return dataKey === "my"
      ? ``
      : dataKey === "all"
      ? `Company`
      : dataKey === "market"
      ? t("barChartText.market")
      : dataKey === "industry"
      ? t("barChartText.industry")
      : dataKey === "company"
      ? userGroup.includes("internal") && isPresentationEnabled && Clients.value
        ? Clients?.label
        : User?.signInUserSession?.idToken?.payload["custom:alt_org_name"]
        ? User?.signInUserSession?.idToken?.payload?.["custom:alt_org_name"]
        : loggedInUserData?.organization?.name
        ? loggedInUserData?.organization?.name
        : "Company"
      : "";
  }

  return (
    <div className={`${hideLineChart ? "" : "card-layout"} w-100`}>
      <Row className="gap-2 gap-md-0 g-4">
        <Col md={hideLineChart ? 12 : 2}>
          <div
            className={`${
              hideLineChart ? "" : "card-layout"
            } h-100 w-100 d-flex ${
              hideLineChart
                ? "flex-row align-items-baseline gap-4 flex-wrap"
                : "flex-md-column align-items-center gap-2"
            }  justify-content-around rounded-5 m-0 `}
            style={{
              boxShadow: hideLineChart
                ? "none"
                : "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
          >
            {finalReportDataKeys.map((key, i) => (
              <div
                key={i}
                className={`d-flex flex-column justify-content-center align-items-center`}
              >
                <h1
                  className="card-title fw-bolder"
                  style={{ color: ChartsColor[i] }}
                >
                  {`${metricsData[i]?.avgDays ? metricsData[i].avgDays : 0}`}
                  <span className="fs-4 ms-1">days</span>
                </h1>
                <h6 className="metrics-label text-center lh-sm">{`${getCustomKeyName(
                  key
                )} ${t("timeToHire.averageTime")}`}</h6>
              </div>
            ))}
          </div>
        </Col>
        {!hideLineChart && (
          <Col md={10}>
            <div
              className={`card-layout h-100 w-100 m-0`}
              style={{
                boxShadow: hideLineChart
                  ? "none"
                  : "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <LineRecharts
                lineData={lineData}
                datakeys={finalReportDataKeys}
                dataLabels={updatedLineChartDataLabels}
                isYAxisContainsPercentData={false}
                yAxisLabelName={"Number of Days"}
              />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default TimeToHireStats;
