import { t } from "i18next";

export const getColumnsEnJaLabelsWithFields = (language) => {
  let current_stage_label =
    window.location.pathname === "/performancereport"
      ? t("performanceReportText.latestStage")
      : t("filterNav.stage");

  const columns = [
    {
      field: "id",
      label: t("performanceReportText.id", { lng: language }),
    },
    {
      field: "app_position_name",
      label: t("sidebarText.position", { lng: language }),
    },
    {
      field: "kanji_last",
      label: t("performanceReportText.lastNameJ", { lng: language }),
    },
    {
      field: "kanji_first",
      label: t("performanceReportText.firstNameJ", { lng: language }),
    },
    {
      field: "current_stage",
      label: current_stage_label,
    },
    {
      field: "current_employer",
      label: t("performanceReportText.employer", { lng: language }),
    },
    {
      field: "current_title",
      label: t("performanceReportText.title", { lng: language }),
    },
    {
      field: "initial_response",
      label: t("performanceReportText.initialResponse", { lng: language }),
    },
    {
      field: "response_reason",
      label: t("barChartText.reason", { lng: language }),
    },
    {
      field: "role",
      label: t("timeToHire.role", { lng: language }),
    },
    {
      field: "focus",
      label: t("timeToHire.focus", { lng: language }),
    },
    {
      field: "salary",
      label: t("performanceReportText.salary", { lng: language }),
    },
    {
      field: "salary_breakdown",
      label: t("performanceReportText.salaryBreakdown", { lng: language }),
    },
    {
      field: "english",
      label: t("performanceReportText.english", { lng: language }),
    },
    {
      field: "japanese",
      label: t("performanceReportText.japanese", { lng: language }),
    },
    {
      field: "first_name",
      label: t("performanceReportText.firstName", { lng: language }),
    },
    {
      field: "last_name",
      label: t("performanceReportText.lastName", { lng: language }),
    },
    {
      field: "linkedin_url",
      label: t("performanceReportText.linkedinUrl", { lng: language }),
    },
    {
      field: "twitter_url",
      label: t("performanceReportText.twitterUrl", { lng: language }),
    },
    {
      field: "facebook_url",
      label: t("performanceReportText.facebookUrl", { lng: language }),
    },
    {
      field: "github_url",
      label: t("performanceReportText.githubUrl", { lng: language }),
    },
    {
      field: "birthday",
      label: "Birth Date",
    },
    {
      field: "age",
      label: t("performanceReportText.age", { lng: language }),
    },
    {
      field: "education_history",
      label: t("performanceReportText.educationHistory", { lng: language }),
    },
    {
      field: "work_history",
      label: t("performanceReportText.workHistory", { lng: language }),
    },
    {
      field: "elapsed_time",
      label: t("timeToHire.elapsedTime", { lng: language }),
    },
    {
      field: "last_activity_date",
      label: t("performanceReportText.lastActivity", { lng: language }),
    },
    {
      field: "last_activity_type",
      label: "Type of Last Activity",
    },
    {
      field: "candidate_manager",
      label: "Candidate Manager",
    },
    {
      field: "business_manager",
      label: "Business Manager",
    },
    {
      field: "client_name",
      label: "Client",
    },
    {
      field: "outreach_url",
      label: "Outreach",
    },
    {
      field: "zoho_url",
      label: "ZOHO",
    },
    {
      field: "trello_url",
      label: "Trello",
    },
    {
      field: "text",
      label: "Notes",
    },
    {
      field: "other_applications",
      label: t("cardViewText.otherApplications", { lng: language }),
    },
  ];

  return columns;
};
