import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const UserProfile = ({ data, isInternalUser = false }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const isJobsPage = location.pathname === "/jobs";
  return (
    <>
      <div className="card mb-4 user-profile">
        <div className="card-body text-center">
          {/* <img
                src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw3NjA4Mjc3NHx8ZW58MHx8fHx8&w=1000&q=80'
                alt='avatar'
                className='profile-image rounded-circle img-fluid'
                style={{ width: '150px' }}
              /> */}
          {(!isJobsPage || isInternalUser) && (
            <>
              <h5 className="profile_subsection-title">
                {data?.first_name} {data?.last_name}{" "}
              </h5>
              {data?.kanji_first !== null && data?.kanji_last !== null && (
                <h5 className="profile_subsection-subtitle mb-2">
                  {data?.kanji_last} {data?.kanji_first}{" "}
                </h5>
              )}
            </>
          )}

          {data?.age && (
            <p className="profile_subsection-body mb-2">
              {t("cardViewText.age")} {data?.age}
            </p>
          )}

          <p className="mb-2 profile_subsection-body">{data?.current_title}</p>
          <p className="mb-4 profile_subsection-body">
            {data?.current_employer}
          </p>
          <div className="d-flex flex-column justify-content-center mb-2 gap-2">
            <div className="badge d-flex flex-column gap-2">
              <span>{t("cardViewText.salary")}</span>
              <span> {data?.salary || "Unknown"}</span>
            </div>

            <div className="badge d-flex flex-column gap-2 text-wrap">
              <span>{t("cardViewText.salaryBreakdown")}</span>
              <span> {data?.salary_breakdown || "Unknown"}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
