import { Row, Col } from "react-bootstrap";
import { isEmpty } from "lodash";

import {
  UserProfile,
  WorkHistory,
  StageHistory,
  CompanyProfile,
  TagsSection,
  ActiveApplications,
  CandidatePitchHistory,
} from "components/CandidateProfileModalContent/SubComponents";
import QnABox from "./SubComponents/QnABox";
import { useTranslation } from "react-i18next";

const ProfileModalBodyLayout = ({
  data = {},
  setBasicActive = () => {},
  child = <></>,
  basicActive = "",
  isInternalUser = false,
  setScrollToDetailSectionId = () => {},
  pitchHistory = [],
  activeApplicationHistory = [],
  questions = {},
  isQnALoading = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container perform-card">
        <Row>
          <Col lg={4}>
            <UserProfile data={data} isInternalUser={isInternalUser} />
            <TagsSection data={data} showTags={isInternalUser} />
            <ActiveApplications
              setActiveTab={setBasicActive}
              isInternalUser={isInternalUser}
              setScrollToDetailSectionId={setScrollToDetailSectionId}
              activeApplicationHistory={activeApplicationHistory}
            />
            {isInternalUser && (
              <CandidatePitchHistory
                pitchHistory={pitchHistory}
                setActiveTab={setBasicActive}
                setScrollToDetailSectionId={setScrollToDetailSectionId}
              />
            )}
            {/* {<OtherApplications data={data} />} */}
          </Col>

          <Col lg={8}>
            {basicActive === "Current Application" && (
              <>
                <CompanyProfile data={data} isInternalUser={isInternalUser} />
                {isInternalUser && (
                  <QnABox questions={questions} isLoading={isQnALoading} />
                )}
                <WorkHistory data={data} />
              </>
            )}

            {child
              ? child
              : !isEmpty(data?.app_position?.stage_history) && (
                  <div className="mb-4">
                    <p className="profile_section-title">
                      {t("cardViewText.applicationHistory")}
                    </p>
                    <StageHistory
                      stageHistory={data?.app_position?.stage_history}
                      isInternalUser={isInternalUser}
                      pitchResponse={data?.app_position?.pitch_response}
                    />
                  </div>
                )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProfileModalBodyLayout;
