import React, { forwardRef, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import HistoryDetailList from "./HistoryDetailList";

import { formatDate, convertStageCodeToName } from "helpers/ReusableFunctions";

import { UserContext } from "context/UserContext";
import AccordionComp from "components/UI/Accordion/AccordionComp";
import { useTranslation } from "react-i18next";

const HistoryDetailsLayout = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    current = {},
    handleToggle = () => {},
    loading: position_id = "",
    id = "",
    accordionBody = <></>,
    showAccordion = true,
    hideCMDetail = false,
    hideStage = false,
  } = props;

  const { language } = useContext(UserContext);

  return (
    <div className="card mb-4" ref={ref}>
      <div className="card-body">
        <Row>
          <HistoryDetailList
            title={"Company"}
            value={
              language === "ja" ? current?.client_name_ja : current?.client_name
            }
          />
          <HistoryDetailList
            title={"Position"}
            value={
              <Link
                to={`https://esai.jobs/jobdetail/${current?.position_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {language === "ja"
                  ? current?.position_name_ja
                  : current?.position_name ?? ""}
              </Link>
            }
          />
        </Row>
        <hr />

        <Row>
          {!hideStage && (
            <HistoryDetailList
              title={t("performanceReportText.latestStage")}
              value={convertStageCodeToName(
                current?.stage,
                current?.pitch_response?.response,
                ""
              )}
            />
          )}
          <HistoryDetailList
            title={"Date"}
            value={formatDate(current?.updated_at)}
          />
        </Row>
        {!hideCMDetail && (
          <>
            <hr />
            <Row>
              <HistoryDetailList
                title={"Candidate Manager"}
                value={`${current?.candidate_manager?.first_name ?? ""}  ${
                  current?.candidate_manager?.last_name ?? ""
                }`}
              />
              <Col></Col>
            </Row>
          </>
        )}
        <hr />
        <Row>
          {showAccordion && (
            <AccordionComp
              id={current?.position_id}
              eventKey={id}
              handleToggle={handleToggle}
              accordionBody={accordionBody}
              minimizeText="Minimize Details"
              expandText="Expand Details"
              isClientSide={false}
              loading={position_id}
            />
          )}
        </Row>
      </div>
    </div>
  );
});

export default HistoryDetailsLayout;
