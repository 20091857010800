import React, { useContext, useMemo } from "react";

import SelectUI from "components/Select/SelectUI";
import { FilterContext } from "context/FilterContext";
import { findStageCodeByKey } from "helpers/ReusableFunctions";
import { PipelineStageChoices } from "helpers/Constant";

import "./style.scss";

const StagesSelector = ({ resetReport = () => {}, isDisabled = false }) => {
  const { dropdownData, filterStore, setFilterStore } =
    useContext(FilterContext);

  const removedStageOptionsForTimeToHire = [2, 90, 91, 92, 93, 94].map(
    (val) => PipelineStageChoices[val]
  );

  const stagesOptions = dropdownData.StageManagersOptions?.filter(
    (option) => !removedStageOptionsForTimeToHire.includes(option.value)
  );

  const stage2Options = useMemo(
    () =>
      stagesOptions.filter(
        (option) =>
          filterStore.StageManagers1?.value !== option.value &&
          +findStageCodeByKey(option.value) >
            +findStageCodeByKey(filterStore.StageManagers1.value)
      ),
    [stagesOptions, filterStore.StageManagers1]
  );

  const handleStage1Change = () => {
    if (filterStore.StageManagers2?.value) {
      setFilterStore((prevFilterStore) => ({
        ...prevFilterStore,
        StageManagers2: [],
      }));
      resetReport();
    }
  };

  const setStageManager1 = (e, entryName) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      [entryName]: e,
    }));
    handleStage1Change();
  };

  const setStageManager2 = (e, entryName) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      [entryName]: e,
    }));
  };

  return (
    <>
      <SelectUI
        options={stagesOptions}
        entryName={"StageManagers1"}
        placeholder={"Select Stage"}
        selectedValue={setStageManager1}
        className="w-100 border-radius-tr-br-0"
        value={filterStore.StageManagers1}
        isDisabled={isDisabled}
      />
      <SelectUI
        options={stage2Options}
        entryName={"StageManagers2"}
        placeholder={"Select Stage"}
        selectedValue={setStageManager2}
        className="w-100 border-radius-tl-bl-0"
        value={filterStore.StageManagers2}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default StagesSelector;
