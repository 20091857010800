import React, { useContext } from "react";
import { Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { FilterContext } from "context/FilterContext";
import {
  PitchDeclinedReasonChoices,
  PitchDeclinedReasonText,
} from "helpers/Constant";
import { formatDate, convertStageCodeToName } from "helpers/ReusableFunctions";
import { HistoryDetailList } from ".";
import { useTranslation } from "react-i18next";

const CompanyProfile = ({ data = {}, isInternalUser = false }) => {
  const { t } = useTranslation();
  const { filterStore, language } = useContext(FilterContext);
  const location = useLocation();

  return (
    <div className="card mb-4">
      <div className="card-body">
        {isInternalUser && location.pathname !== "/performancereport" && (
          <>
            <Row>
              <HistoryDetailList
                title={"Company"}
                value={data?.app_position?.client_name}
              />
            </Row>
            <hr />
          </>
        )}

        <Row>
          <HistoryDetailList
            title={t("cardViewText.position")}
            value={
              data?.app_position?.position_name ? (
                <Link
                  to={`https://esai.jobs/jobdetail/${data?.app_position?.position_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data?.app_position?.position_name}
                </Link>
              ) : null
            }
          />
        </Row>

        <hr />
        <Row>
          <HistoryDetailList
            title={t("cardViewText.applicationStatus")}
            value={convertStageCodeToName(
              data?.app_position?.current_stage,
              data?.app_position?.pitch_response?.response,
              ""
            )}
          />
        </Row>

        {["no"].includes(filterStore.PitchResponse) && (
          <>
            <hr />
            <Row>
              <HistoryDetailList
                title={t("cardViewText.reasonsForNo")}
                value={
                  PitchDeclinedReasonText(language)?.[
                    PitchDeclinedReasonChoices?.[
                      data.app_position?.pitch_response.reason
                    ]
                  ]
                }
              />
            </Row>
          </>
        )}
        <hr />
        <Row>
          {data?.app_position?.candidate_manager && isInternalUser && (
            <HistoryDetailList
              title={"Candidate Manager"}
              value={`${data?.app_position?.candidate_manager?.first_name} ${data.app_position.candidate_manager?.last_name}`}
            />
          )}

          <HistoryDetailList
            title={"Date"}
            value={
              data?.app_position?.last_updated
                ? formatDate(data.app_position.last_updated)
                : ""
            }
          />
        </Row>
      </div>
    </div>
  );
};

export default CompanyProfile;
